import { useState, useRef } from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import { Link } from 'react-router-dom';

function Users() {
  return (
    <div className="App-view">
      <h3>PartCAD Adoption Programs</h3>
      <Row lg={12} style={{margin: 20, width: "80%"}}>
        <center>
        We are onboarding a limited number of clients to pioneer
        the next generation of mechanical design and manufacturing technologies.
        <br/>
        <br/>
        Do you think differently?
        <br/>
        Do you want to lead the way?
        <br/>
        Are you ready for the future?
        <br/>
        <br/>
        </center>
      </Row>

      <Row lg={12} style={{width: "80%"}}>
        <Col sm={6} md={6} lg={6}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title>
                2024 Batch
              </Card.Title>
              <Card.Body>
                A free program for a limited number of pilot projects.
                No hard expectations on engagement from your team.
              </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={6} lg={6}>
          <Card style={{backgroundColor: '#333', color: '#ccc', marginBottom: 20}}>
              <Card.Title>
                2025 H1 Batch
              </Card.Title>
              <Card.Body>
                Consulting engagements to help your team to learn new technologies.
                Exact terms are discussed on per-project basis.
              </Card.Body>
          </Card>
        </Col>
      </Row>
      <center style={{ width: "75%", marginTop: 40 }}>
        Interested?
        <br/>
        <Button href="https://calendly.com/partcad-support/30min">Let's talk!</Button>
      </center>
    </div>
  );
}

export default Users;

import logo from './../assets/images/logo.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          {/* <Navbar.Brand href="/" style={{fontFamily: "Helvetica", fontWeight: 900}}> */}
          <Navbar.Brand style={{fontFamily: "Helvetica", fontWeight: 900}}>
            <Link className='navbar-brand' to="/">
              <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              PartCAD
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <Link className='nav-link' to="/repository">Repository</Link>
              {/* <Nav.Link href="/features">Features</Nav.Link> */}
              <Link className='nav-link' to="/users">Users</Link>
              <Link className='nav-link' to="/consulting">Support</Link>
              {/*<Nav.Link href="/consume">Consume</Nav.Link>
              <Nav.Link href="/produce">Produce</Nav.Link> */}
              <Nav.Link href="https://partcad.readthedocs.io/">Documentation</Nav.Link>
              <Nav.Link href="/contribute">Contribute</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;

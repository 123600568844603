import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './views/Home';
import Repository from './views/Repository';
import Features from './views/Features';
import Consulting from './views/Consulting';
import Contribute from './views/Contribute';
import Users from './views/Users';
import Consume from './views/Consume';
import Produce from './views/Produce';
import Support from './views/Support';
import About from './views/About';
import Footer from './components/Footer';

import ReactGA from 'react-ga4';

ReactGA.initialize('G-0294H8RGT9');

function Analytics() {
  let location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
}

function App() {
  if (
    window.location.hostname.indexOf("thingdoc") !== -1 ||
    window.location.hostname.indexOf("www") !== -1
  ) {
    window.location.replace("https://partcad.org/");
    return (<></>);
  }

  return (
    <Router>
      <Header />
      <Routes>
        {/* <Route path="/repository/:item_kind/:item_name*" element={<Repository />} /> */}
        <Route path="/repository/*" element={<Repository />} />
        <Route path="/features" element={<Features />} />
        <Route path="/users" element={<Users />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/contribute" element={<Contribute />} />
        <Route path="/consume" element={<Consume />} />
        <Route path="/produce" element={<Produce />} />
        <Route path="/support" element={<Support />} />
        <Route path="/about" element={<About />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
      <Footer />
      <Analytics />
    </Router>
  );
}

export default App;
